import * as OAuthMethods from "@octokit/oauth-methods";
export async function checkTokenWithState(state, options) {
    return await OAuthMethods.checkToken({
        // @ts-expect-error not worth the extra code to appease TS
        clientType: state.clientType,
        clientId: state.clientId,
        clientSecret: state.clientSecret,
        request: state.octokit.request,
        ...options,
    });
}
