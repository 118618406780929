import { request as defaultRequest } from "@octokit/request";
import btoa from "btoa-lite";
export async function checkToken(options) {
    const request = options.request ||
        /* istanbul ignore next: we always pass a custom request in tests */
        defaultRequest;
    const response = await request("POST /applications/{client_id}/token", {
        headers: {
            authorization: `basic ${btoa(`${options.clientId}:${options.clientSecret}`)}`,
        },
        client_id: options.clientId,
        access_token: options.token,
    });
    const authentication = {
        clientType: options.clientType,
        clientId: options.clientId,
        clientSecret: options.clientSecret,
        token: options.token,
        scopes: response.data.scopes,
    };
    if (options.clientType === "github-app") {
        delete authentication.scopes;
    }
    return { ...response, authentication };
}
